import React, { FC, useContext, useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useLayoutEffect } from 'react';
import { Page } from '../ts/interfaces/Generic';

import {
  FlightResultPage,
  FlightDetailsPage,
  MultiFlightPage,
  FlightBookPage,
  MultiFlightBookPage,
  FlightSuccessPage,
  FlightPaymentPage,
} from '@bepro-travel/fe.flight';
import {
  BookHotelPage,
  ResultHotelPage,
  DetailsHotelPage,
  SuccessHotelPage,
  BookPayment,
} from '@bepro-travel/fe.hotel';
import {
  ResultTransferPage,
  BookTransferPage,
  SuccessTransferPage,
  TransferPaymentPage,
} from '@bepro-travel/fe.transfer';
import {
  ResultTourPage,
  DetailsPackagePage,
  DetailsTourPage,
  SuccessPackagePage,
  BookPackagePage,
  ResultPackagePage,
  PackageOfflineDetailsPage,
  PackageOfflineBookPage,
  PackageOfflineSuccessPage,
  PaymentPackagePage,
  PackageOfflinePaymentPage,
} from '@bepro-travel/fe.package';
import { MainContext } from '../contexts/MainContext';
import {
  DetailsDynamicPage,
  BookDynamicPage,
  DetailsPredefinedPage,
  DetailsDynamicFHPage,
  DynamicPaymentPage,
  DynamicSuccessPage,
} from '@bepro-travel/fe.dynamic';
import {
  MainPage,
  Login,
  FailPage,
  MainMOPage,
  StatusXXPage,
  NotFoundPage,
} from '@bepro-travel/fe.shared';
import { setBgStyles, setSliderStyles } from '../utils/cms';
import usePreserveUTMParams from '../hooks/usePreserveUTMParams';
import { cdnUrl } from '../constants/generic';
import { getRandomImage } from '../services/cms';

const ResultHotel: FC = () => {
  const { proposal, setProposal } = useContext(MainContext);
  return <ResultHotelPage proposal={proposal} setProposal={setProposal} />;
};

const MainPageWr: FC = () => {
  const location = useLocation();
  useEffect(() => {
    setSliderStyles();
    setBgStyles();
  }, [location]);
  return <MainPage />;
};

const LoginPage: FC = () => {
  useEffect(() => {
    (async () => {
      const response = await getRandomImage();

      const elemLogin = document.querySelector('.login-top') as HTMLElement;
      elemLogin.style.backgroundImage = `url(${cdnUrl + response?.imageUrl})`;
    })();
  }, []);

  return (
    <div className="login-top">
      <Login />
    </div>
  );
};

const Router = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user, setUser } = useContext(MainContext);
  usePreserveUTMParams();

  useEffect(() => {
    if ((!user || !user.isAuth) && location.pathname !== '/login') {
      navigate('/login');
    }
  }, [location]); // eslint-disable-line

  useEffect(() => {
    if (user && user.isAuthExpired) {
      navigate('/login');
      const newUser = { ...user, isAuthExpired: false };
      setUser(newUser);
      localStorage.setItem('user', JSON.stringify(newUser));
    }
  }, [user]); // eslint-disable-line

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  const pages: Page[] = [
    { path: '/login', component: LoginPage },
    { path: '/', component: MainPageWr },
    { path: '/hotels', component: MainPageWr },
    { path: '/hotels/*', component: MainPageWr },
    { path: '/hotels/results', component: ResultHotel },
    { path: '/hotel/details', component: DetailsHotelPage },
    { path: '/hotel/book', component: BookHotelPage },
    { path: '/hotel/payment', component: BookPayment },
    { path: '/hotel/success', component: SuccessHotelPage },
    { path: '/flights', component: MainPageWr },
    { path: '/flights/*', component: MainPageWr },
    { path: '/flights/results', component: FlightResultPage },
    { path: '/flight', component: FlightDetailsPage },
    { path: '/multiflight', component: MultiFlightPage },
    { path: '/multiflight/book', component: MultiFlightBookPage },
    { path: '/flight/book', component: FlightBookPage },
    { path: '/flight/payment', component: FlightPaymentPage },
    { path: '/flight/success', component: FlightSuccessPage },
    { path: '/tours', component: MainPageWr },
    { path: '/tours/results', component: ResultTourPage },
    { path: '/tour/details', component: DetailsTourPage },
    { path: '/tour/book', component: BookPackagePage },
    { path: '/tour/payment', component: PaymentPackagePage },
    { path: '/tour/success', component: SuccessPackagePage },
    { path: '/ilpackages', component: MainPageWr },
    { path: '/ilpackages/results', component: ResultPackagePage },
    { path: '/ilpackage/details', component: DetailsPackagePage },
    { path: '/ilpackage/book', component: BookPackagePage },
    { path: '/ilpackage/payment', component: PaymentPackagePage },
    { path: '/ilpackage/success', component: SuccessPackagePage },
    { path: '/offline-package/details', component: PackageOfflineDetailsPage },
    { path: '/offline-package/payment', component: PackageOfflinePaymentPage },
    { path: '/offline-package/book', component: PackageOfflineBookPage },
    { path: '/offline-package/success', component: PackageOfflineSuccessPage },
    { path: '/transfers', component: MainPageWr },
    { path: '/transfers/results', component: ResultTransferPage },
    { path: '/transfer/book', component: BookTransferPage },
    { path: '/transfer/payment', component: TransferPaymentPage },
    { path: '/transfer/success', component: SuccessTransferPage },
    { path: '/dynamic', component: MainPageWr },
    { path: '/dynamic/details', component: DetailsDynamicPage },
    { path: '/dynamic/predefined', component: DetailsPredefinedPage },
    { path: '/dynamic/payment', component: DynamicPaymentPage },
    { path: '/dynamic/success', component: DynamicSuccessPage },
    { path: '/dynamic-fh', component: MainPageWr },
    { path: '/dynamic-fh/details', component: DetailsDynamicFHPage },
    { path: '/dynamic/predefined', component: DetailsPredefinedPage },
    { path: '/dynamic/book', component: BookDynamicPage },
    { path: '/destinations', component: MainPageWr },
    { path: '/destinations/*', component: MainPageWr },
    { path: '/pages', component: MainPageWr },
    { path: '/pages/*', component: MainPageWr },
    { path: '/my-orders', component: MainMOPage },
    { path: '/fail', component: FailPage },
    { path: '/status-xx', component: StatusXXPage },
  ];

  return (
    <Routes>
      {pages.map(({ component: Component, path }) => {
        return <Route key={path} element={<Component />} path={path} />;
      })}

      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default Router;
