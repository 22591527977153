import { FC, Fragment } from 'react';
import { Page } from '../ts/interfaces/CmsPageResponse';
import { SearchHotel } from '@bepro-travel/fe.hotel';
import { SearchFlight } from '@bepro-travel/fe.flight';
import { SearchTransfer } from '@bepro-travel/fe.transfer';
import { SearchPackages, SearchTours } from '@bepro-travel/fe.package';
import MultiSearchBox from './MultiSearchBox';
import { SearchDynamic, SearchDynamicAM, SearchDynamicFH } from '@bepro-travel/fe.dynamic';

interface SearchBoxProps {
  searchBox: string;
  page?: Page;
}

const SearchBox: FC<SearchBoxProps> = ({ searchBox, page }) => {
  return (
    <Fragment>
      {searchBox === 'Multi' && <MultiSearchBox config={page?.params?.param_2} />}
      {searchBox === 'Hotels' && (
        <SearchHotel
          loadingSearch={false}
          onClickStop={() => {}}
          predefined={{
            lat: page?.geoCode.latitude ? +page?.geoCode.latitude : 0,
            lon: page?.geoCode.longitude ? +page?.geoCode.longitude : 0,
            city: page?.city.name || '',
            id: page?.country.code || '',
            types: '',
          }}
        />
      )}
      {searchBox === 'Flights' && (
        <SearchFlight
          loadingSearch={false}
          onClickStop={() => {}}
          destination={{
            arrivalCode: page?.city.code || '',
            arrivalDesc: page?.city.name || '',
          }}
        />
      )}
      {searchBox === 'Transfers' && <SearchTransfer />}
      {searchBox === 'Nofshon' && <SearchPackages />}
      {searchBox === 'Tours' && <SearchTours />}
      {!!searchBox && (
        <Fragment>
          {['Dynamic_FMHM', 'Dynamic_FMHMTU'].includes(searchBox) && (
            <SearchDynamicFH matrixConfig={searchBox} />
          )}
          {['Dynamic_FMHMSM', 'Dynamic_FMHMSMTU'].includes(searchBox) && (
            <SearchDynamic matrixConfig={searchBox} param1={page?.params?.param_1} />
          )}
          {['Dynamic_AM'].includes(searchBox) && <SearchDynamicAM />}
        </Fragment>
      )}
    </Fragment>
  );
};

export default SearchBox;
